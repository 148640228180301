<template>
<div class="container-fluid mt-5 pt-5">
    <BasePageBreadCrumb :pages="pages" title="config. funções" :menu="menu"/>

    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-6">
                    <div class="card">
                        <div class="card-body pt-4">
                            <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Função</label>
                                                <input type="text" v-model="title.name" class="form-control" :class="{'is-invalid': errors.has('name')}" name="name" v-validate="'required'">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="submit" class="btn btn-info">{{ id ? 'Salvar' : 'Cadastrar' }}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12 col-lg-6">
                    <div class="col-12">
                        <div class="form-group d-flex">
                            <input type="search" v-model="search" class="form-control campo-procurar" placeholder="Procurar">
                            <button type="button" @click="goSearch" class="btn btn-info">Buscar</button>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body pt-4">
                            <form class="needs-validation" novalidate @submit.prevent="update">
                                <div class="form-body">
                                    <table class="table no-wrap v-middle mb-0">
                                        <thead>
                                            <tr class="border-0">
                                                <th class="border-0 text-muted pt-0 px-2 text-center"><span>Cadastrados</span></th>
                                                <th class="border-0 font-14 font-weight-medium text-muted"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in titles" :key="item.id">
                                                <td class="font-weight-medium text-dark border-top-0 px-2">
                                                    <input type="text" class="form-control" placeholder="" v-model="titles[index].name">
                                                </td>
                                                <td class="border-top-0 text-muted">
                                                    <select class="custom-select mr-sm-2" v-model="titles[index].is_active">
                                                        <option :value="1">Ativa</option>
                                                        <option :value="0">Inativa</option>
                                                    </select>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row justify-content-center" v-show="titles.length == 0">
                                    Nenhuma função encontrada.
                                </div>

                                <div class="form-actions" v-show="titles.length > 0">
                                    <div class="text-right">
                                        <button type="submit" class="btn btn-info">Atualizar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import TitleService from "@/services/resources/TitleService";
const service = TitleService.build();

export default {
  data() {
    return {
      title: {
          name: null
      },
      id: null,
      titles: [],
      search: '',
      pages:[
            {
                name: 'dashboard',
                to: 'Dashboard'
            }
       ],
       menu:[
            {
                name: 'Nova função',
                to: 'TimeTitle'
            }
       ]
    };
  },
  methods: {
    goSearch(){
        if(this.search.length == 0)
            this.fetchStones();

        this.titles = this.titles.filter(elem => {
            return elem.name.toLowerCase().includes(this.search.toLowerCase());
        });
    },
    edit(index){
        this.id = this.titles[index].id;
        this.title = {
            name: this.titles[index].name
        }
    },
    save(){
      this.$validator.validateAll().then((result) => {
        if (result) {
            let title = Object.assign({}, this.title)
            service
            .create(title)
            .then(resp => {
                this.$bvToast.toast('Sua função foi criada com sucesso!', {
                    title: 'Função criada',
                    autoHideDelay: 5000,
                    type: 'success'
                })
                this.$validator.reset();
                this.fetchTitles();
            })
            .catch(err => {
                console.log(err)
            })
        }
      });
    },
    checkField(){
        return this.titles.filter(elem => {
            return elem.name.length == 0;
        }).length;
    },
    update(){

        if(this.checkField()){
            this.$bvToast.toast('Algum campo está inválido, por favor, verifique.', {
                title: 'Campo inválido',
                autoHideDelay: 5000,
                type: 'danger'
            })
            return;
        }

        let data = {
          id: 0,
          titles: this.titles
        }
    
        service
        .update(data)
        .then(resp => {
            this.$bvToast.toast('Sua função foi atualizada com sucesso!', {
                title: 'Função atualizada',
                autoHideDelay: 5000,
                type: 'success'
            })
        })
        .catch(err => {
            console.log(err)
        })
    },
    fetchTitles(){

        service
          .search()
            .then(resp => {
              this.titles = resp;
            })
            .catch(err => {
              console.log(err)
            })
    }

  },
  mounted() {
      this.fetchTitles();
  },
};
</script>
<style scoped lang="scss">
.bg{
  background: #fff;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;
}
</style>